import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "nateShoemaker",
  "categories": ["rails", "ruby", "lambda"],
  "date": "2020-07-21",
  "path": "/blog/lambda-planning-and-architecture",
  "summary": "AWS Lambda architecting tips and tricks.",
  "title": "Ruby on AWS Lambda: Planning & Architecting",
  "image": "./ogp.png",
  "seo": {
    "og": {
      "type": "article",
      "title": "Ruby on AWS Lambda: Planning & Architecting",
      "description": "AWS Lambda architecting tips and tricks."
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`This article is part of our Ruby on AWS Lambda blog series. A recent project had us migrating an existing pdf document processing system from Rails Sidekiq to AWS Lambda. The processing includes OCR, creating preview images, splicing the pdf, and more. Moving to Lambda reduced processing time by 300% in some cases; parallelization for the win!`}</em></p>
    <p><em parentName="p">{`This series will serve less as a step-by-step process to get OCR serverless infrastructure up and running and more of a highlight reel of our "Aha!" moments. In part one, we talked about creating an AWS Lambda Layer with Docker. In part two, we'll chat about architecting a serverless app. Check out the other posts in the series:`}</em></p>
    <ul>
      <li parentName="ul"><em parentName="li"><a parentName="em" {...{
            "href": "/blog/lambda-layer-dependencies"
          }}>{`Layer Dependencies`}</a></em></li>
      <li parentName="ul"><em parentName="li"><a parentName="em" {...{
            "href": "/blog/aws-lambda-ship-it"
          }}>{`Package & Ship It!`}</a></em></li>
      <li parentName="ul"><em parentName="li"><a parentName="em" {...{
            "href": "/blog/lambda-and-active-storage"
          }}>{`Integrating with Active Storage`}</a></em></li>
    </ul>
    <h2>{`The Problem`}</h2>
    <p>{`Rails devs, tell me if you've been here before: you have small units of work that should be processed concurrently, so you reach for Sidekiq. Solved! Well, usually solved. Sidekiq is an amazing tool that we use at Hint, and it solves most concurrency problems.`}</p>
    <p>{`However, a bottleneck is always a possibility. In our case, the document processor we engineered could process a PDF with one page, or 500. But since each page has to be processed and there is no page limit when uploading, it was common to process hundreds of pages. This work took much too time with Sidekiq, even with tens of workers. We wanted to see the same performance whether the PDF a user uploads has 50 pages, or 500.`}</p>
    <h2>{`The Solution`}</h2>
    <p>{`AWS Lambda was a good solution for our performance problem. It allows us to run our workers in parallel independent of the number of pages we are processing. However, Lambda doesn't give you any guidance on `}<em parentName="p">{`how`}</em>{` to design a serverless application. You are provided the basic building blocks: a function that calls other functions, and the ability to use any other AWS service. Quite open-ended! Luckily, there are some standard architecture practices that have emerged in the serverless community. We'll be focusing on the most popular strategy: fan-out, fan-in.`}</p>
    <h3>{`"What do you mean, software architecture?"`}</h3>
    <p>{`Glad you asked! Martin Fowler said it best:`}</p>
    <blockquote>
      <p parentName="blockquote">{`When people in the software industry talk about “architecture”, they refer to a hazily defined notion of the most important aspects of the internal design of a software system.`}</p>
    </blockquote>
    <p>{`When we talk about architecture in the context of Lambda, we'll be touching on function composition, utilizing other AWS services, and how the application communicates with the real world. If you haven't, check out `}<a parentName="p" {...{
        "href": "https://martinfowler.com/architecture/"
      }}>{`Martin Fowler's thoughts on software architecture`}</a>{`.`}</p>
    <h2>{`Fan-out, Fan-in`}</h2>
    <p>{`The fan-out, fan-in architecture is simple: one function starts `}<inlineCode parentName="p">{`n`}</inlineCode>{` number of functions, and when those finish, another function does something with the results. The first function is referred to as the ventilator (or vent). The vent function calls worker functions. Once the worker functions finish, the sink function is called. So, vent -> workers -> sink.`}</p>
    <p>{`For example, let's use a hypothetical 100-page pdf.. The vent function is triggered from an outside application and 100 worker functions are called. Each worker process a single page. The sink function is then invoked when all 100 are done, and we do things with the result. In theory, 1,000,000 pages should take as long as 100, since all the processing is happening in parallel. Let's go into a little bit more detail about the whole process:`}</p>
    <ul>
      <li parentName="ul">{`User uploads PDF from Rails app`}</li>
      <li parentName="ul">{`PDF is uploaded to S3 via Active Storage`}</li>
      <li parentName="ul">{`Vent function is triggered with the file key as an argument`}</li>
      <li parentName="ul">{`Vent function calls `}<inlineCode parentName="li">{`n`}</inlineCode>{` number of worker functions, passing file key as an argument so workers can find the file on S3`}</li>
      <li parentName="ul">{`Worker function processes page, stores preview image, collects information about page and stores that in DynamoDB, and a record in DynamoDB that holds the keeps track of the pages left is decreased by one`}</li>
      <li parentName="ul">{`If the worker is the final worker in the queue, the sink function is called`}</li>
      <li parentName="ul">{`Sink function collects all page information from DynamoDB and sends results as JSON back to the Rails app`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://aws.amazon.com/dynamodb/"
      }}>{`DynamoDB`}</a>{` is a NoSQL database offered by AWS. When using the fan-out, fan-in pattern you must keep track of progress so the sink function can be called when all workers are completed. The nature of serverless means that no state can be held, thus an external, stateful service must be used. In our case, we are using it as a glorified counter. When a worker function finishes, we decrease the records count column (`}<inlineCode parentName="p">{`pages_left`}</inlineCode>{`) by one:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`decrement_pages_left`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`job_id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    db `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Aws`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`DynamoDB`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Client`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{`
    resp `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` db`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`update_item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      table_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`                  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"documents-`}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span><span parentName="span" {...{
                "className": "token constant"
              }}>{`ENV`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'RUBY_ENV'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`                         `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` job_id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` job_id `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      update_expression`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`           `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'set pages_left = pages_left - :val'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      expression_attribute_values`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`':val'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      return_values`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`               `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'UPDATED_NEW'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    invoke_sink_function`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`job_id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` resp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:attributes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'pages_left'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Note that the sink function is then called if there are no pages left to process. Easy!`}</p>
    <p>{`For a more in-depth look into the fan-out, fan-in pattern take a look at `}<a parentName="p" {...{
        "href": "https://theburningmonk.com/2018/04/how-to-do-fan-out-and-fan-in-with-aws-lambda/"
      }}>{`this fantastic blog post by Yan Cui`}</a>{`.`}</p>
    <h2>{`Knowing what language is better suited for a certain task`}</h2>
    <p>{`We initially used Ruby for our vent, worker, and sink functions. However, we hit a bottleneck in the vent function. Invoking hundreds of concurrent network requests (which are IO bound) is not one of Ruby's strong suits. Using any kind of library that takes advantage of CPU cores doesn't gain any advantage because of Lambda's CPU limitations (more on that in the next section). So, what language does Lambda support that has asynchronous IO bound operations out of the box? JavaScript!`}</p>
    <p>{`Now, this isn't a bash on Ruby. All programming languages have their strengths and weaknesses, and a rewrite in a different language should be well researched and thought out beforehand. Luckily for us, we have lots of Ruby `}<em parentName="p">{`and`}</em>{` JS experience. Also, the vent function encapsulates very little business logic, so a rewrite would be a good fit if an initial spike proved fruitful. And it did! When processing a 186-page document, the vent function took 30 seconds in Ruby, and 2 seconds in JS. Nailed it!`}</p>
    <p>{`If you experience performance issues on Lambda, make sure to research the problem thoroughly. The cool thing about Lambda is that you can use different languages throughout the application process. If part of the process would perform much better with a different language, try it out!`}</p>
    <h2>{`CPU/RAM limitations`}</h2>
    <p>{`When assigning resources to your Lambda functions, memory is the only configurable option. Why? Well, there is no obvious answer in the UI, and even worse, it's hidden `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/lambda/faqs/"
      }}>{`deep in a FAQ`}</a>{`. When you choose the amount of memory you want to allocate for a function, you are given proportional CPU power and other resources. What the docs don't tell you, however, is that if you allocate `}<em parentName="p">{`enough`}</em>{` memory, you'll be given two cores instead of one.`}</p>
    <p><a parentName="p" {...{
        "href": "https://stackoverflow.com/a/47582392"
      }}>{`This Stack Overflow comment`}</a>{` has more info. TL;DR: if you are using 1.8GB or more of memory, you get to use two CPU cores, instead of one. Even two cores is not a lot, and that's why asynchronous operations in programming languages that utilize multiple cores don't perform well on Lambda. When architecting a serverless application, it's better to split larger tasks into smaller subtasks, when possible.`}</p>
    <p>{`I hope you learned something new today! Architecting serverless applications come with their own unique set of challenges, but the community has great solutions that have been production-tested for quite some time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      